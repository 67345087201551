<template>
	<div class="">
		<qy-top-nav title="商品详情" />
		<div style="overflow: auto" :style="{ height: containerHeight }" ref="container">
			<van-swipe class="my-swipe" indicator-color="white">
				<van-swipe-item v-for="(banner, index) in bannerList" style="position: relative">
					<template v-if="banner.url.indexOf('.mp4') > -1">
						<video :ref="'video-' + index" class="video-js" style="width: 100%; height: 100%; max-height: 200px" preload="true" controls :poster="banner.poster">
							<source :src="banner.url" type="video/mp4" />
						</video>
						<!-- <van-icon class="playIcon" name="play-circle-o" @click="playVideo(index)"/> -->
					</template>
					<img v-else :src="banner.url" />
				</van-swipe-item>
			</van-swipe>
			<div style="color: red; background: #fff; padding: 10px 0 0px 20px; position: relative">
				<span style="font-size: 16px; margin-right: 6px">¥</span>
				<span style="font-size: 24px">{{ formatMoney(select.instance.wholesalePrice, '面议') }}</span>
				<div style="position: absolute; right: 10px; top: 8px;display:none;">
					<a
						style="
							color: #fff;
							float: right;
							text-align: right;
							padding: 0 10px;
							background: #d51c20;
							line-height: 30px;
							display: inline-block;
							border-radius: 4px;
							text-align: center;
							font-size: 14px;
						"
						:href="'tel:' + goods.shopPhone"
					>
						<van-icon style="position: relative; left: -4px; top: 2px" name="phone" />
						电话联系
					</a>
				</div>
			</div>
			<div class="baseInfo">
				<span class="van-multi-ellipsis--l2" style="font-size: 18px; font-weight: bold; flex-grow: 1">
					<van-tag type="danger">{{ goods.goodsTag }}</van-tag>
					{{ goods.goodsRemark }}
					<span style="color: red">{{ goods.brand ? '【' + goods.brand + '】' : '' }}</span>
				</span>
				<span class="likeIcon" @click="follow">
					<van-icon :name="followIcon" color="red" />
					<br />
					收藏
				</span>
			</div>
			<div class="baseInfo guige">
				<span style="font-weight: bold; flex-shrink: 0">已选</span>
				<span style="flex-grow: 1">{{ selectDesc }}</span>
				<span @click="showGuige"><img :src="moreImg" /></span>
			</div>
			<div class="baseInfo" style="flex-wrap: wrap">
				<span v-for="s in goods.goodsDetail.goodsService" style="font-size: 13px; flex-shrink: 0">
					<van-icon name="passed" color="red" style="position: relative; top: 2px" />
					{{ s }}
				</span>
			</div>
			<van-tabs v-model="active" @click="onClick">
				<van-tab title="商品详情" class="goodsDesc" name="goodsDetail">
					<div style="padding: 6px 8px">
						<div v-for="param in goods.goodsDetail.paramList" style="margin: 6px 4px">
							<span style="display: inline-block; width: auto; text-align: left; padding-right: 10px">{{ param.label }}:</span>
							<span>{{ param.value }}</span>
						</div>
					</div>
					<div class="" style="padding: 6px 8px">
						<img v-for="detailImg in goods.goodsDetail.imgList" :src="getImgUrl(detailImg)" />
					</div>
				</van-tab>
				<van-tab title="商品评价" class="goodsDesc" name="goodsComment">
					<van-cell-group v-for="item in dataList">
						<van-cell>
							<!--评价商品与评价级别-->
							<div>
								<div class="comment-goods">
									<div class="block-inline img-block">
										<van-image alt="用户头像" width="50" height="50" :src="getImgUrl(item.userAvatar)" />
									</div>
									<div class="block-inline name-block">
										<div>{{ item.hideName == 1 ? '匿名用户' : item.userName }}</div>
										<van-rate v-model="item.commentLevel" :size="14" readonly />
									</div>
									<div class="block-inline time-block">
										{{ item.commentTime }}
									</div>
								</div>
							</div>
							<!--评价内容-->
							<div class="comment">
								<span>{{ item.comment }}</span>
							</div>
							<!--评价图片-->
							<div class="comment-imgs" v-for="img in item.commentImgs">
								<van-image width="80" height="55" :src="getImgUrl(img)" />
							</div>
						</van-cell>
					</van-cell-group>
					<van-cell-group id="comment-btn">
						<van-cell v-if="dataList.length <= 0">
							<van-empty description="暂无评价" />
						</van-cell>
						<van-cell v-if="dataList.length > 0">
							<van-button plain round @click="queryAllGoodsComment">查看全部评价</van-button>
						</van-cell>
					</van-cell-group>
				</van-tab>
			</van-tabs>
			<div class="articleTitle">猜你喜欢>>></div>
			<van-list v-model="loading" :finished="finished" finished-text="没有更多了" :offset="50" @load="refresh">
				<template #default>
					<van-grid :column-num="2" :gutter="5">
						<van-grid-item v-for="goods in goodsList" @click="redirectTo('/goodsInfo', { goodsId: goods.goodsId, instanceId: goods.instanceId })">
							<div style="position: relative" :style="{ width: goodsLogoSize, height: goodsLogoSize }">
								<van-tag type="danger" style="position: absolute; top: 0;">{{ goods.goodsTag }}</van-tag>
								<van-tag type="danger" style="position: absolute; top: 0; right: 0;">{{ goods.brand }}</van-tag>
								<img :src="getImgUrl(goods.goodsLogo)" style="width: 100%; height: 100%;border-radius: 4px;" />
							</div>
							<div style="text-align: left; color: #333; width: 100%; margin-top: 6px; font-size: 14px">
								<div class="van-multi-ellipsis--l2">{{ goods.goodsRemark }}</div>
								<div class="van-multi-ellipsis--l2" style="font-size: 13px; color: #999; margin: 5px 0">
									{{ getInstanceDesc(goods, true) }}
								</div>
								<div class="flex-between" @click.stop="" style="font-size: 12px; color: #999; margin: 5px 0; justify-content: flex-end">
									<!-- <span style="position: relative;top:-2px;">{{goods.viewNum}}人浏览</span> -->

									<div class="flex-between" style="color: #333">
										<div style="padding: 5px; background-color: #f2f3f5"><van-icon name="minus" @click.stop="addCartNum(goods, -1)" /></div>
										<span style="padding: 5px; min-width: 20px; text-align: center; margin: 0 2px; background-color: #f2f3f5">{{ goods.cartNum }}</span>
										<div style="padding: 5px; background-color: #f2f3f5"><van-icon name="plus" @click.stop="addCartNum(goods, 1)" /></div>
									</div>
								</div>
								<div class="flex-between" style="font-size: 12px; color: #999">
									<!-- <span style="position: relative;top:-2px;">{{goods.viewNum}}人浏览</span> -->
									<!-- <span style="position: relative;text-decoration: line-through;">¥ {{qy.formatMoney(goods.goodsPrice)}}</span> -->
									<span style="font-size: 12px; color: red">
										<span style="font-size: 12px; color: red">¥</span>
										{{ qy.formatMoney(goods.wholesalePrice) }}
									</span>
									<span style="">库存：{{ goods.stockNum }}</span>
								</div>
							</div>
						</van-grid-item>
					</van-grid>
				</template>
			</van-list>

			<van-sku
				v-model="guigeShow"
				:sku="sku"
				:initial-sku="initialSku"
				:goods="select"
				:goods-id="select.instanceId"
				:quota="0"
				:hide-stock="false"
				@buy-clicked="onBuyClicked"
				:start-sale-num="select.instance.startSaleNum"
				@sku-selected="onSelectInstance"
				@add-cart="onAddCartClicked"
				ref="sku"
			></van-sku>
		</div>
		<van-goods-action>
			<van-goods-action-icon icon="share" text="分享" @click="showPoster = true" />
			<!-- <van-goods-action-icon icon="shop-o" text="店铺" :to="'/shop?id='+goods.shopId" /> -->
			<van-goods-action-icon icon="cart-o" text="购物车" to="/shoppingCart" />
			<van-goods-action-icon icon="shop-o" text="店铺" :to="'/shop?id='+goods.shopId" />
			<van-goods-action-button type="warning" text="加入购物车" @click="guigeShow = true" />
			<van-goods-action-button type="danger" text="立即购买" @click="guigeShow = true" />
		</van-goods-action>
		<div style="position: fixed; top: 0; height: 100%; width: 100%; z-index: 999999" v-show="showPoster">
			<!-- <va    -->
			<div style="position: absolute; width: 100%; height: 100%; background: #000; opacity: 0.5" @click="showPoster = false"></div>
			<van-image
				id="posterContentImg"
				ref="posterContentImg"
				style="width: 80%; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%)"
				:src="posterContentImg"
			/>
			<div style="position: absolute; text-align: center; bottom: 12px; width: 100%; color: #fff; font-size: 13px">长按图片分享</div>
		</div>
	</div>
</template>
<script type="text/javascript">
import request from '@/utils/request';
import wx from 'weixin-js-sdk';
import { Toast } from 'vant';
import $ from 'jquery';
import { ImagePreview } from 'vant';
export default {
	name: 'Favorite',
	components: {
		[ImagePreview.Component.name]: ImagePreview.Component
	},
	data() {
		return {
			showPoster: false,
			guigeShow: false,
			dataList: [],
			active: 'goodsDetail',
			select: {
				instanceId: null,
				picture: null,
				price: null,
				defaultPicture: null,
				instance: {}
			},
			goods: {
				goodsId: null,
				instanceId: null,
				goodsRemark: null,
				goodsDetail: {},
				follow: false,
				shopId: null,
				goodsTag: null
			},
			initialSku: {},
			sku: {
				price: 0, // 默认价格（单位元）
				stock_num: 1, // 商品总库存
				none_sku: false, // 是否无规格商品
				tree: [],
				list: []
			},

			containerHeight: 0,
			moreImg: require('@/assets/images/more.png'),
			bannerList: [],
			loading: false,
			finished: false,
			containerHeight: 0,
			listHeight: 0,
			showFilter: false,
			pageNum: 1,
			pageSize: 5,
			goodsLogoSize: 0,
			goodsList: [],
			isRequesting: false,
			wxConfig: null,
			proxiedUser: {},
			posterContentImg: '',
			proxyId: 0
		};
	},
	computed: {
		followIcon() {
			return this.goods.follow ? 'like' : 'like-o';
		},
		selectDesc() {
			let desc = '';
			let instance = this.select.instance;
			if (instance) {
				let arr = [];
				for (let i = 1, k; i <= 4; i++) {
					k = 'param' + i + 'Val';
					if (instance[k]) {
						arr.push(instance[k]);
					}
				}
				desc = arr.join();
			}
			return desc;
		}
	},
	created() {
		let proxyStg = this.storage.get('proxyStg');
		if (proxyStg) {
			this.proxyId = JSON.parse(proxyStg).id;
		}
		this.proxiedUser = this.getProxiedUser();
		this.onClick();
		this.goodsLogoSize = document.documentElement.clientWidth / 2 - 25 + 'px';
		this.getShareInfo();
	},
	mounted() {
		this.containerHeight = document.documentElement.clientHeight - 110 + 'px';
		// console.log(this.$refs.container.style)
		this.updateWxShareConfig();
	},

	watch: {
		$route(to, from) {
			this.active = 'goodsDetail';
			let $ = this.$;
			$(this.$refs.container).scrollTop(0);
			this.onClick();
			this.pageNum = 1;
			this.goodsList = [];
			this.refresh();
			this.getShareInfo();
			this.updateWxShareConfig();
		}
	},
	methods: {
		updateWxShareConfig() {
			this.refreshWxConfig(wx, () => {
				let goods = this.goods;
				let share_config = {
					imgUrl: location.origin + goods.defaultLogo, // 这里是需要展示的图标
					desc: goods.goodsRemark, // 这是分享展示的摘要
					title: goods.goodsName, // 这是分享展示卡片的标题
					link: location.href, // 这里是分享的网址
					success: function (res) {
						//alert(JSON.stringify(res));
					}
				};
				wx.updateAppMessageShareData(share_config); // 自定义“分享给朋友”及“分享到QQ”
				// let timeListShareConfig={...share_config};
				// timeListShareConfig.
				wx.updateTimelineShareData(share_config); // 自定义“分享到朋友圈”及“分享到QQ空间”按钮的分享内容
			});
		},
		getShareInfo(instanceId) {
			instanceId=instanceId||this.getUrlParam('instanceId');
			let goodsId=this.getUrlParam('goodsId');
			if(!instanceId||!goodsId){
				return;
			}
			this.doPost('/api/share/goods',{
					instanceId:instanceId ,
					goodsId: goodsId,
					url: location.href
				},(rs) => {
					if (rs.success) {
						this.posterContentImg = 'data:image/png;base64,' + rs.data;
					}
				}
			);
		},
		playVideo(index) {
			this.$refs['video-' + index][0].play();
			//console.log(this.$refs['video-' + index])
		},
		refresh() {
			if (this.isRequesting) {
				return;
			} else {
				this.isRequesting = true;
			}
			request
				.post('/api/goods/recommend', {
					shopId: this.goods.shopId || '',
					proxyId: this.proxyId || '',
					userId: this.proxiedUser.userId,
					pageNum: this.pageNum++,
					pageSize: this.pageSize
				})
				.then((response) => {
					this.loading = false;
					this.isRequesting = false;
					if (response.rows.length < this.pageSize || response.total <= (this.pageNum - 1) * this.pageSize) {
						this.finished = true;
					}
					if (response.total <= (this.pageNum - 1) * this.pageSize) {
						this.finished = true;
					}
					if (!response.rows && response.rows.length == 0) {
						return;
					}
					response.rows.sort(function () {
						return parseInt(Math.random() * 100) % 2 == 0;
					});
					for (let i = response.rows.length - 1; i >= 0; i--) {
						if (response.rows[i].id == this.goods.goodsId) {
							response.rows.splice(i, 1);
							break;
						}
					}
					this.goodsList = this.goodsList.concat(response.rows);
				});
		},

		onClick(name, title) {
			let that = this;
			switch (that.active) {
				case 'goodsDetail':
					that.queryGoodsDetail();
					break;
				case 'goodsComment':
					that.queryTop5GoodsCommentList();
					break;
				default:
					break;
			}
		},
		queryAllGoodsComment() {
			this.$router.push({
				path: '/goodsComment',
				query: {
					goodsId: this.goods.goodsId
				}
			});
		},
		queryTop5GoodsCommentList() {
			let that = this;
			request
				.post('/api/comment/queryTop5GoodsCommentList', {
					goodsId: this.goods.goodsId
				})
				.then((res) => {
					if (res.code == 0) {
						let data = res.data;
						let list = data.list;
						list.forEach((item) => {
							if (item.commentImg) {
								item.commentImgs = item.commentImg.split(',');
								delete item.commentImg;
							} else {
								item.commentImgs = [];
							}
						});
						that.dataList = list;
					} else {
						Toast.fail(res.msg);
					}
				});
		},
		async queryGoodsDetail() {
			let rs = await request.post('/api/goods/detail?proxyId=' + this.proxyId + '&goodsId=' + this.getUrlParam('goodsId'));
			if (rs.success) {
				let { bannerImgs, instanceList, defaultPrice, defaultLogo } = rs.data;
				let goodsDetail = (rs.data.goodsDetail = JSON.parse(rs.data.goodsDetail));
				if (goodsDetail.goodsService) {
					goodsDetail.goodsService = goodsDetail.goodsService.split(',');
				} else {
					goodsDetail.goodsService = [];
				}
				this.goods = rs.data;
				this.select.picture = this.select.defaultPicture = this.getImgUrl(defaultLogo);
				this.select.instanceId = rs.data.defaultInstanceId;
				this.getShareInfo(this.select.instanceId)
				this.updateBanner(bannerImgs);
				this.updateSku(instanceList);
				this.browse();
				//this.initDetailPreview();
			}
		},
		updateBanner(bannerStr) {
			if (bannerStr) {
				let arr = JSON.parse(bannerStr);
				if (arr.length < 1) {
					return;
				}
				let bannerDataList = [];
				for (let i = 0; i < arr.length; i++) {
					let bannerInfo = {
						url: this.getImgUrl(arr[i])
					};
					if (arr[i].indexOf('mp4') > -1) {
						let nextItem = this.getImgUrl(arr[i + 1]);
						if (nextItem && nextItem.indexOf('mp4') < 0) {
							bannerInfo.poster = nextItem;
							i++;
						}
					}
					bannerDataList.push(bannerInfo);
				}
				// console.log(bannerDataList)
				this.bannerList = bannerDataList;
			}
		},
		updateSku(instanceList) {
			if (!instanceList) {
				return;
			}
			let selectInstance = instanceList[0],
				tree = [],
				tempObj = {},
				instanceId = this.getUrlParam('instanceId'),
				maxPrice = -1,
				minPrice = 100000000,
				initialSku = {
					selectedNum: 1
				};
			instanceList.forEach((item) => {
				item.price = item.wholesalePrice * 100;
				item.stock_num = item.stockNum;
				minPrice = parseFloat(item.wholesalePrice) > minPrice ? minPrice : item.wholesalePrice;
				maxPrice = item.wholesalePrice < maxPrice ? maxPrice : item.wholesalePrice;
				if (instanceId == item.instanceId) {
					selectInstance = item;
				}

				for (let i = 0; i < 4; i++) {
					let title = item['param' + (i + 1) + 'Name'],
						field = 'param' + (i + 1) + 'Val';
					if (!title) {
						break;
					}

					if (!tree[i]) {
						tree.push({
							k: title,
							k_s: field,
							v: [],
							largeImageMode: false //  是否展示大图模式
						});
					}
					let uniKey = field + '-' + item[field];
					if (!tempObj[uniKey]) {
						tree[i].v.push({
							id: item[field],
							name: item[field]
						});
						tempObj[uniKey] = true;
					}
				}
			});

			for (let i = 0; i < 4; i++) {
				let title = selectInstance['param' + (i + 1) + 'Name'],
					field = 'param' + (i + 1) + 'Val';
				if (!title) {
					break;
				}
				initialSku[field] = selectInstance[field];
			}
			this.initialSku = initialSku;
			this.select.instance = selectInstance;
			// console.log(selectInstance)
			this.sku.price = selectInstance.wholesalePrice;
			this.sku.stock_num = selectInstance.stockNum;
			this.sku.tree = tree;
			this.sku.list = instanceList;
		},
		browse() {
			if (this.isLogin()) {
				this.setSilent();
				request.post('/api/browse/goods', {
					goodsId: this.goods.goodsId,
					shopId: this.goods.shopId
				});
			}
		},
		showGuige() {
			this.guigeShow = true;
		},
		onBuyClicked(info) {
			let isLogin = this.checkLogin();
			if (!isLogin) {
				return;
			}
			let instance = info.selectedSkuComb;
			this.storage.set(
				'goodsList',
				JSON.stringify([
					{
						goodsNum: info.selectedNum,
						goodsId: instance.goodsId,
						goodsInstanceId: instance.instanceId,
						goodsLogo: instance.goodsLogo,
						wholesalePrice: instance.wholesalePrice,
						goodsPrice: instance.goodsPrice,
						goodsName: this.goods.goodsName,
						goodsRemark: this.goods.goodsRemark,
						goodsDesc: this.goods.goodsRemark,
						shopId: this.goods.shopId,
						shopName: this.goods.shopName
					}
				])
			);
			this.$router.push('/orderAdd');
		},
		onAddCartClicked(info) {
			let isLogin = this.checkLogin();
			if (!isLogin) {
				return;
			}
			let instance = info.selectedSkuComb;
			request
				.post('/api/shopCart/add', {
					goodsInstanceId: this.select.instance.instanceId,
					addPrice: this.select.instance.wholesalePrice,
					goodsNum: info.selectedNum
				})
				.then((rs) => {
					Toast('加入成功');
				});
		},
		follow() {
			if (this.goods.follow) {
				this.Dialog.confirm({
					message: '确定要取消关注吗'
				}).then(this.doFollow);
			} else {
				this.doFollow();
			}
		},
		doFollow() {
			request
				.post('/api/follow/addOrDel', {
					goodsId: this.goods.goodsId,
					shopId: this.goods.shopId
				})
				.then((res) => {
					if (res.success) {
						this.goods.follow = !this.goods.follow;
					} else {
						Toast.fail(res.msg);
					}
				});
		},
		onSelectInstance({ skuValue, selectedSku, selectedSkuComb }) {
			let instance = this.$refs.sku.getSkuData().selectedSkuComb;
			if (instance == null) {
				this.select.picture = this.select.defaultPicture;
			} else {
				this.select.instance = instance;
				this.select.picture = this.getImgUrl(instance.goodsLogo);
			}
		},
		initDetailPreview() {
			setTimeout(function () {
				$('#goodsDetailHtml img').click(function () {
					let url = $(this).attr('src');
					ImagePreview({
						images: [url],
						showIndex: false,
						startPosition: 0
					});
				});
			}, 1000);
		}
	}
};
</script>
<style type="text/css" scoped="">
.goodsDesc {
	min-height: 300px;
}

.van-swipe img {
	width: 100%;
}

.baseInfo {
	background: #fff;
	display: flex;
	padding: 10px 10px 10px 0;
	align-items: center;
	border-radius: 4px;
}

.baseInfo span {
	margin-left: 10px;
}

.baseInfo .van-tag {
	position: relative;
	top: -3px;
	padding: 2px 8px;
	border-radius: 3px;
}

.van-tabs {
	background: #fff;
	margin-top: 10px;
	overflow: auto;
}

.likeIcon {
	width: 60px;
	text-align: center;
	flex-shrink: 0;
	position: relative;
	display: inline-block;
}

.likeIcon:before {
	position: absolute;
	content: '';
	width: 1px;
	top: 10px;
	bottom: 10px;
	left: 0px;
	background: #ededed;
}

.guige {
	background: #f7f7f7;
	margin: 0px 5px;
	padding: 0px 5px;
}

.guigeTable {
	border-collapse: collapse;
	border-spacing: 0;
	margin-top: 10px;
	margin-left: 10px;
	width: calc(100% - 20px);
}

.guigeTable td {
	padding: 8px;
	border: 1px solid #dadada;
	text-align: left;
	font-size: 12px;
}

.guigeTable tr td:first-child {
	width: 72px;
}

.articleTitle {
	color: #c00;
}

.img-block {
	width: 20%;
}

.name-block {
	width: 55%;
}

.time-block {
	width: 25%;
}

.block-inline {
	height: 50px;
	display: inline-block;
	/*line-height: 50px;*/
	float: left;
}

.comment-goods img {
	width: 50px;
	height: 50px;
}

.comment-imgs {
	display: inline-block;
	margin-right: 3px;
}

.comment-imgs img {
	width: 85px;
	height: 65px;
	padding: 5px 5px;
}

.comment-level span {
	position: relative;
	left: 15px;
}

#comment-btn .van-cell__value--alone {
	text-align: center;
}

.goodsDesc img {
	max-width: 100%;
}

.my-swipe img {
	max-width: 100%;
	max-height: 200px;
}

.playIcon {
	font-size: 40px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.goodsInfo {
	display: flex;
	justify-content: space-between;
	/* text-align: left; */
	width: 100%;
	color: red;
	align-items: end;
}

.articleTitle {
	padding: 0px 10px;
	color: orange;
	background: #fff;
	color: #c00;
	position: relative;
	line-height: 30px;
}

.articleTitle:after {
	content: '';
	position: absolute;
	height: 1px;
	background: #ededed;
	left: 10px;
	right: 10px;
	bottom: 0px;
}
</style>
<style>
.goodsDesc img {
	width: 100%;
}
</style>